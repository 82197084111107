/* Add these styles to the top of your existing CSS */

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: relative; /* Allows absolute positioning of contact icons */
}

.contact-details {
  font-size: 0.9rem;
  color: #333; /* Dark text */
}

.container h1 {
  text-align: center;
  margin-top: 40px; /* Adjusted margin for title */
  font-size: 2.5em;
  font-weight: 600;
  color: #333; /* Dark text color */
}

.topbar__marquee {
  overflow: hidden;
  background-color: transparent;
  margin-bottom: 100px; /* Increase this value to add more space below the marquee */
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
}

.marquee-inner {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 45s linear infinite; /* Increased from 30s to 60s */
}

.marquee-content span {
  display: inline-block;
  padding: 0 100px;
  font-size: 22px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  margin-bottom: 40px;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

body {
  background: #f9f9f9; /* Light background */
  color: #333; /* Dark text */
  font-family: 'Inter', sans-serif; /* Modern font */
  min-height: 100vh;
}

.container {
  max-width: 1000px; /* Wider container */
  margin: 0 auto;
  padding: 2rem;
}

.navbar {
  padding: 1rem 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff; /* White background */
  
}

.nav-list {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-list li {
  margin: 0 1rem;
  
}

.nav-list button {
  background: none;
  border: none; /* Remove borders */
  color: #333; /* Dark text */
  cursor: pointer;
  font-size: 1.2rem; /* Increase font size */
  padding: 0.5rem 1rem;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.nav-list li.active button {
  color: #333; /* Keep text color consistent */
  font-weight: bold; /* Make active section bold */
}

.nav-list button:hover {
  text-decoration: underline; /* Underline on hover */
}

.projects-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.project-card {
    position: relative;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

.project-card:hover {
  transform: translateY(-5px);
}

.project-item h3 {
    font-weight: bold;
    margin-bottom: 5px;
  }

.project-card a:hover {
  background-color: #005bb5; /* Darker accent color */
}

.contact-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
}

.contact-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #111;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.contact-item:hover {
  background-color: #222;
}

.contact-item svg {
  margin-right: 0.5rem;
}

.blog-post {
  background-color: #111;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
}

.blog-post h3 {
  margin-top: 0;
}

.blog-post a {
  color: #fff;
  text-decoration: none;
}

.blog-post p {
  margin-top: 0.5rem;
}

.contact-icons {
  display: flex;
  gap: 1rem;
  position: absolute;
  right: 20px; /* Aligns contact icons to the right */
}

.navigation {
  display: flex;
  justify-content: center; /* Centers the navigation */
  flex-grow: 1;
}

.contact-icons a {
  color: #333; /* Dark text color */
  font-size: 1.5rem; /* Icon size */
  transition: color 0.3s ease;
}

.contact-icons a:hover {
  color: #0073e6; /* Accent color on hover */
}

.about h1 {
    font-size: 3em; /* Increase font size for the title */
    font-weight: 600;
    color: #333;
    text-align: center;
    margin-top: 40px;
  }
  
  .about p {
    font-size: 1.2em; /* Increase font size for the blurb */
    line-height: 1.5em; /* Add more spacing between lines */
    color: #555;
    text-align: left;
    margin-top: 20px;
  }

  .work {
    padding: 20px;
  }
  
  .timeline {
    position: relative;
    margin: 0 auto;
    padding: 10px 0;
    list-style: none;
  }
  
  .timeline-item {
    position: relative;
    margin-bottom: 20px;
    padding-left: 40px;
  }
  
  .timeline-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: #3498db;
    border-radius: 50%;
  }
  
  .timeline-year {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .timeline-content {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

.project-title-link {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline */
}

.project-title-link:hover {
  text-decoration: underline; /* Underline on hover */
}

.blog-grid {
    display: flex;
    flex-direction: column; /* Arrange items in a single column */
    gap: 1rem;
    padding: 1rem; /* Add padding to the grid */
  }
  

.blog-card {
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-title-link {
  color: inherit;
  text-decoration: none;
}

.blog-title-link:hover {
  text-decoration: underline;
}